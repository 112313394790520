@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























.app-section-root-fees {
  height: calc(100vh - 104px);
}
